import React, { FunctionComponent } from 'react';

interface Props {
  label: string;
  id: string;
  name: string;
  textArea?: boolean;
  type?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const FormGroup: FunctionComponent<Props> = (props: Props) => {
  const {
    label,
    id,
    name,
    textArea = false,
    type = 'text',
    value,
    onChange,
  } = props;

  return (
    <div className="form-group">
      <label htmlFor={id} className="form-group__label">{label}</label>

      {textArea ? (
        <textarea
          id={id}
          className="form-group__input"
          name={name}
          rows={8}
          value={value}
          onChange={onChange}
        />
      ) : (
        <input
          id={id}
          className="form-group__input"
          name={name}
          type={type}
          value={value}
          onChange={onChange}
        />
      )}
    </div>
  );
};

export default FormGroup;
