import React, { FunctionComponent, useState } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import Image from '../components/Image';
import SEO from '../components/SEO';
import FormGroup from '../components/FormGroup';
import Button from '../components/Button';

const ContactPage: FunctionComponent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  return (
    <Layout>
      <SEO title="Contact Chase" />

      <div className="hero">
        <Image className="hero__image left-mobile" fileName="contact-hero.png" />

        <div className="hero__overlay" />

        <div className="hero__content">
          <div className="wrapper">
            <h1>Coach. Podcaster. COACHCASTER.</h1>

            <p className="hero__blurb">Health coach turned podcaster, Chase interviews industry leaders in fitness nutrition and mindset. Messages to help you live a life EVER FORWARD.</p>

            <h2>Chase Chewning, MS, ACE CHC</h2>

            <div className="actions">
              <a
                href="/https://podcasts.apple.com/gb/podcast/ever-forward-radio/id1195967486"
                className="button button--blue"
                target="_blank"
                rel="noreferrer noopener"
              >
                Listen on Apple Podcasts
              </a>

              <p>or</p>

              <Link to="/contact" className="button button--white">Contact Me</Link>
            </div>
          </div>
        </div>
      </div>

      <section className="container" style={{ paddingBottom: 50 }}>
        <h2 className="text-center section-header">Contact</h2>

        <p className="text-center" style={{ marginTop: 50 }}>My purpose and passion in life is helping others live a life EVER FORWARD. Seeking information about being a guest on Ever Forward Radio or feel that Chase would be of value to have on your podcast or speaking event? Would you like more information about how Ever Forward Coach can help you with your personal or professional development? For any of these inquiries simply fill out the information request below and Chase, or someone from his team, will contact you as soon as possible!</p>

        <form
          className="contact-form"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          method="POST"
          name="contact"
          style={{ marginTop: 50 }}
        >
          <input type="hidden" name="form-name" value="contact" />

          <div className="grid">
            <div className="grid__col--xs-12 grid__col--md-6">
              <FormGroup
                id="name"
                label="Name"
                name="name"
                value={name}
                onChange={(event) => setName(event.currentTarget.value)}
              />
            </div>

            <div className="grid__col--xs-12 grid__col--md-6">
              <FormGroup
                id="email"
                label="Email"
                name="email"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </div>
          </div>

          <FormGroup
            id="subject"
            label="Subject"
            name="subject"
            value={subject}
            onChange={(event) => setSubject(event.currentTarget.value)}
          />

          <FormGroup
            id="message"
            label="Message"
            name="message"
            textArea
            value={message}
            onChange={(event) => setMessage(event.currentTarget.value)}
          />

          <div className="text-right">
            <Button
              label="Submit"
              type="submit"
            />
          </div>
        </form>
      </section>
    </Layout>
  );
};

export default ContactPage;
