import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

interface Props {
  full?: boolean;
  label: string;
  showLoader?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
}

const Button: FunctionComponent<Props> = (props: Props) => {
  const {
    full,
    label,
    showLoader,
    type = 'button',
    onClick,
  } = props;

  return (
    <button
      className={classnames('button', { 'button button--full': full })}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
    >
      {showLoader ? <div className="button-loader" /> : label}
    </button>
  );
};

export default Button;
